import { BFormGroup, BModal, BOverlay } from '@/components/bootstrap'
import FormInput from '@/components/controls/form/FormInput'
import { FieldValidator, notEmpty, notSpaces } from '@/helpers/validation'
import { emptyLocalizedField, LocalizedField } from '@/store/i18nStore'
import { BvEvent } from 'bootstrap-vue'
import { Component } from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import ColorPicker from '@/components/controls/ColorPicker'
import { ITag } from '@/store/blogStore'
import { blogStore } from '@/store'

@Component
export default class BlogTagModal extends tsx.Component<{
  onClose?: () => void
}> {
  modalId = 'blog-tag-modal'
  pending = false

  state: 'new' | 'edit' = 'new'

  label = new FieldValidator<LocalizedField>({
    value: emptyLocalizedField(),
    validators: [notEmpty()],
    localized: true,
  })
  alias = new FieldValidator({
    value: '',
    validators: [notEmpty(), notSpaces()],
  })
  color = '#7367F0'
  id = -1

  get modalTitle() {
    if (this.state === 'new') {
      return 'Создать категорию'
    }
    return 'Редактировать категорию'
  }

  get okButtonTitle() {
    if (this.state === 'new') {
      return 'Создать'
    }
    return 'Сохранить'
  }

  async show(tag?: ITag) {
    this.$bvModal.show(this.modalId)
    this.pending = true

    this.state = tag ? 'edit' : 'new'

    if (tag) {
      this.id = tag.id
      this.color = tag.color
      this.label.set(tag.label)
      this.alias.set(tag.alias)
    }

    this.pending = false
  }

  async submit(e: BvEvent) {
    e.preventDefault()

    if (!this.label.validate()) {
      return
    }
    this.pending = true

    let result = false
    const data = {
      id: this.id,
      label: this.label.value,
      alias: this.alias.value,
      color: this.color,
    }

    if (this.state === 'new') {
      result = await blogStore.createTag(data)
    } else {
      result = await blogStore.saveTag(data)
    }
    this.pending = false
    if (!result) {
      return
    }

    this.$emit('close')
    this.$bvModal.hide(this.modalId)
  }

  onHidden() {
    this.id = -1
    this.label.set(emptyLocalizedField())
    this.alias.set('')
    this.label.reset()
    this.alias.reset()
    this.pending = false
  }

  protected render() {
    return (
      <BModal
        id={this.modalId}
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        title={this.modalTitle}
        ok-title={this.okButtonTitle}
        cancel-title="Отмена"
        busy={this.pending}
        onHidden={this.onHidden}
        onOk={this.submit}
      >
        <BOverlay show={this.pending} no-wrap />

        <FormInput label="Название" fieldValidator={this.label} />
        <FormInput label="Alias" fieldValidator={this.alias} />
        <BFormGroup label="Цвет">
          <ColorPicker
            value={this.color}
            onInput={value => (this.color = value)}
          />
        </BFormGroup>
      </BModal>
    )
  }
}

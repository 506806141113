import BlogTagModal from '@/components/blog/TagModal'
import { BButton, BCard } from '@/components/bootstrap'
import Tag from '@/components/common/Tag'
import Select from '@/components/controls/Select'
import DeleteConfirmationModal from '@/components/DeleteConfirmationModal'
import Link from '@/components/Link'
import WayupTable from '@/components/WayupTable'
import { CellData, Column, Sort } from '@/components/WayupTable/types'
import { showError } from '@/helpers/notifications'
import axios from '@/libs/axios'
import { blogStore } from '@/store'
import { ITag } from '@/store/blogStore'
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'

interface IArticle {
  id: number
  title: string
  alias: string
  previewImage: string
  publishDate: string
  realViewsCount: number
  fakeViewsCount: number
  tag: {
    id: number
    label: string
    color: string
  }
}

@Component
export default class BlogPage extends Vue {
  @Ref() readonly articlesTable!: WayupTable
  @Ref() readonly blogTagModal!: BlogTagModal
  @Ref() readonly deleteConfirmationModal!: DeleteConfirmationModal

  columns: Column<IArticle>[] = [
    { field: 'id', centered: true, sortable: true },
    { field: 'previewImage', title: 'Превью' },
    { field: 'title', title: 'Заголовок' },
    { field: 'tag', title: 'Тег', centered: true },
    {
      field: 'publishDate',
      title: 'Дата публикации',
      type: 'dateTime',
      centered: true,
      sortable: true,
    },
    {
      field: 'realViewsCount',
      title: 'Просмотры',
      type: 'number',
      centered: true,
    },
    { customField: 'buttons', centered: true },
  ]

  get selectedTag() {
    return blogStore.selectedTag
  }

  @Watch('selectedTag')
  onTagChanged() {
    this.articlesTable.refresh()
  }

  async mounted() {
    blogStore.fetchTags()
  }

  async fetchBlogs(
    page: number,
    perPage: number,
    search: string,
    sort: Sort<IArticle> | null,
  ) {
    const params = new URLSearchParams()
    params.append('limit', perPage.toString())
    params.append('offset', ((page - 1) * perPage).toString())
    if (blogStore.selectedTag) {
      params.append('tagId', blogStore.selectedTag.id.toString())
    }
    if (sort) {
      params.append('orderBy', sort.field)
      params.append('orderDir', sort.dir)
    }

    const response = await axios.get<{ articles: IArticle[]; total: number }>(
      `v1/blog/articles`,
      { params },
    )

    return { rows: response.data.articles, totalRows: response.data.total }
  }

  async removeTag() {
    const result = await this.deleteConfirmationModal.show(
      blogStore.selectedTag!.label.ru!,
    )

    if (result) {
      await blogStore.removeTag(blogStore.selectedTag!.id)
    }
  }

  async removeArticle(article: IArticle) {
    const result = await this.deleteConfirmationModal.show(article.title)

    if (result) {
      try {
        await axios.delete(`v1/blog/articles/${article.id}`)
        this.articlesTable.refresh()
      } catch (error) {
        console.error(error)
        showError('При удалении статьи произошла ошибка')
      }
    }
  }

  renderCell({ column, row }: CellData<IArticle>) {
    if (column.customField === 'buttons') {
      return (
        <div class="d-flex">
          <BButton
            href={`${process.env.VUE_APP_MSC_URL}/blog/${row.alias}`}
            target="_blank"
            variant="flat-primary"
            class="px-50"
          >
            <feather-icon icon="ExternalLinkIcon" />
          </BButton>
          <BButton
            onClick={() => this.removeArticle(row)}
            variant="flat-danger"
            class="px-50"
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
        </div>
      )
    }

    switch (column.field) {
      case 'previewImage':
        return (
          <img
            src={row.previewImage}
            alt=""
            style="width: 90px"
            class="rounded"
          />
        )
      case 'title':
        return <Link to={`/blog/edit/${row.id}`}>{row.title}</Link>
      case 'tag':
        return <Tag label={row.tag.label} color={row.tag.color} />
      case 'realViewsCount':
        return (
          <span
            v-b-tooltip
            title={`Реальных - ${row.realViewsCount}, фейковых - ${row.fakeViewsCount}`}
          >
            {row.realViewsCount + row.fakeViewsCount}
          </span>
        )
    }
  }

  protected render() {
    return (
      <BCard no-body>
        <div class="d-flex m-1">
          <div class="flex-grow-1">
            <BButton to="/blog/add" variant="primary" class="mr-1 ">
              Добавить статью
            </BButton>
          </div>
          <Select
            value={blogStore.selectedTag}
            onInput={value => (blogStore.selectedTag = value)}
            options={blogStore.tags}
            placeholder="Выберите категорию"
            label="label.ru"
            scopedSlots={{
              option: ({ option }: { option: ITag }) => (
                <Tag
                  label={option.label.ru!}
                  color={option.color}
                  class="m-50"
                />
              ),
              selectedOption: (option: ITag) => (
                <Tag
                  label={option.label.ru!}
                  color={option.color}
                  class="mx-50"
                />
              ),
            }}
          />
          <BButton
            variant="primary"
            onClick={() => this.blogTagModal.show()}
            class="ml-1"
          >
            Добавить категорию
          </BButton>
          <BButton
            onClick={() => this.blogTagModal.show(blogStore.selectedTag!)}
            disabled={!blogStore.selectedTag}
            variant="outline-primary"
            class="mx-50"
          >
            <feather-icon icon="Edit3Icon" />
          </BButton>
          <BButton
            onClick={this.removeTag}
            disabled={!blogStore.selectedTag}
            variant="outline-danger"
          >
            <feather-icon icon="TrashIcon" />
          </BButton>
        </div>
        <WayupTable
          ref="articlesTable"
          columns={this.columns}
          fetchFunction={this.fetchBlogs}
          sort={{ field: 'publishDate', dir: 'desc' }}
          paginationEnabled
          scopedSlots={{ default: this.renderCell }}
        />

        <BlogTagModal ref="blogTagModal" />
        <DeleteConfirmationModal ref="deleteConfirmationModal" />
      </BCard>
    )
  }
}
